import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import './App.css'

function App() {
  return (
    <Router>
      <div style={{ backgroundColor: '#091c29' }} className="relative flex flex-col flex-wrap justify-between min-h-screen max-w-screen items-center">
        <Navbar />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
