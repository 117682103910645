import garantiLogo from "../img/garanti-logo.png";
import vodafoneLogo from "../img/vodafone-logo.png";
import trendyolLogo from "../img/trendyolcom_logo.jpg";

function Experience() {
    return (
        <div className="flex flex-row flex-wrap justify-center min-h-screen">
            <div className="container max-w-7xl py-14 px-4 mx-auto flex flex-wrap font-philosopher text-lg">
                <div className="flex relative pt-10 sm:items-center md:w-2/3 mx-auto">
                    <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                    </div>
                    <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-400 text-white relative z-10 title-font font-medium text-sm">1</div>
                    <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                        <div className="flex-shrink-0 w-24 h-24 bg-white text-indigo-400 rounded-full inline-flex items-center justify-center">
                            <img src={garantiLogo} alt="garantiLogo" className="rounded-full w-20 h-20" />
                        </div>
                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                            <h2 className="font-medium title-font text-white mb-1 text-xl">Garanti BBVA (2018 - 2020)</h2>
                            <p className="leading-relaxed text-white">At Garanti BBVA, I had an excellent start to my career as a software engineer. I gained invaluable insights from experienced professionals, learning both technical skills and key business processes. I primarily worked with COBOL and Cool:Gen, which was an engaging experience for me as a recent graduate. This opportunity not only enhanced my technical abilities but also significantly boosted my confidence as a software engineer. My time at Garanti BBVA was instrumental in shaping my professional development.</p>
                        </div>
                    </div>
                </div>
                <div className="flex relative pt-10 sm:items-center md:w-2/3 mx-auto">
                    <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                    </div>
                    <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-400 text-white relative z-10 title-font font-medium text-sm">2</div>
                    <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                        <div className="flex-shrink-0 w-24 h-24 bg-white text-indigo-400 rounded-full inline-flex items-center justify-center">
                            <img src={vodafoneLogo} alt="vodafoneLogo" className="rounded-full w-24 h-24" />
                        </div>
                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                            <h2 className="font-medium title-font text-white mb-1 text-xl">Vodafone (2020 - 2022)</h2>
                            <p className="leading-relaxed text-white">As a software engineer, I greatly enjoyed my work with the TOBi squad, which focuses on our chatbot application integrated as a web view within the Vodafone mobile app. The project utilizes a microservices architecture built on Red Hat OpenShift, featuring Spring Boot for the backend and React for the frontend. This dynamic environment provided an enriching experience, allowing me to contribute to innovative solutions and collaborate with a talented team.</p>
                        </div>
                    </div>
                </div>
                <div className="flex relative pb-20 pt-10 sm:items-center md:w-2/3 mx-auto">
                    <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                    </div>
                    <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-400 text-white relative z-10 title-font font-medium text-sm">3</div>
                    <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                        <div className="flex-shrink-0 w-24 h-24 bg-white text-indigo-400 rounded-full inline-flex items-center justify-center">
                            <img src={trendyolLogo} alt="trendyolLogo" className="rounded-full w-24 h-24" />
                        </div>
                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                            <h2 className="font-medium title-font text-white mb-1 text-xl">Trendyol (2022 - ...)</h2>
                            <p className="leading-relaxed text-white">After gaining valuable experience in banking and telecommunications, I’m excited to take on a new challenge in e-commerce. I have had the opportunity to work with several teams, and I’m currently part of the Engagement Products team, which focuses on enhancing customer engagement. In this role, we use programming languages such as Kotlin, Go, and Java; databases like Couchbase, Elasticsearch, and PostgreSQL; monitoring tools such as Grafana, New Relic, and Kibana; and version control with GitLab.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Experience;