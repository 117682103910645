import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./Home";
import Experience from "./Experience";
import Contact from "./Contact";
import Stack from "./Stack";

function Navbar() {
    return (
        <Router>
            <nav className="flex flex-wrap flex-col order-first w-full mt-6">
                <ul className="flex flex-wrap flex-row justify-center gap-4 mx-auto text-xl font-middleEarth text-white">
                    <li>
                        <Link className="hover:text-indigo-400" to="/">Home</Link>
                    </li>
                    <li>
                        <Link className="hover:text-indigo-400" to="/experience">Experience</Link>
                    </li>
                    <li>
                        <Link className="hover:text-indigo-400" to="/contact">Contact</Link>
                    </li>
                </ul>
            </nav>

            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
                <Route path="/experience">
                    <Experience />
                    <Stack />
                </Route>
                <Route path="/contact">
                    <Contact />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch >
        </Router>
    );
}

export default Navbar;