import profilePhoto from "../img/profilePhoto.jpg";
import javaLogo from "../img/javaLogo.png";
import springLogo from "../img/springLogo.png";
import reactLogo from "../img/reactLogo.png";
import goLangLogo from "../img/golang_logo.png";
export default {
    header: {
        img: profilePhoto,
        text: ['Hi!', "It's me Serkan.", 'I am '],
        typical: [
            'software engineer. 🖥',
            2000,
            'Lord of the Rings lover. 📹',
            2000,
            'technology enthusiast.',
            2000,
        ],
        btnText: 'Discover More',
    },

    stack: {
        title: 'Stack',
        tech: [
            {
                img: javaLogo,
                alt: 'java',
            },
            {
                img: springLogo,
                alt: 'spring',
            },
            {
                img: reactLogo,
                alt: 'react',
            },
            {
                img: goLangLogo,
                alt: 'golang',
            },
        ],
        desc: `These are my daily use of tech stacks. If you want to learn more about my technical competencies, please send me an email or a message.`,
    },
};